$(document).ready(() => {
  gtag('event', 'select_content', {
    'content_type': 'Platform Visit'
  });

  let item_data = $('[data-ga-item_name]');

  // Event pages
  if ($('.event-page-name').length > 0) {
    gtag('event', 'view_item', {
      value: item_data.data('ga-value'),
      currency: 'USD',
      'items': [
        {
          'item_id': item_data.data('ga-item_id'),
          'item_name': item_data.data('ga-item_name'),
        }
      ]
    });
  }

  // Adding booking to the cart
  window.trackAddToCart = () => {
    gtag('event', 'add_to_cart', {
      'value': $('#total_price_holder').text(),
      'currency': 'USD',
      'items': [
        {
          'item_id': item_data.data('ga-item_id'),
          'item_name': item_data.data('ga-item_name'),
        }
      ]
    });
  }

  // One of success payment pages
  const urlParams = new URLSearchParams(window.location.search);
  const successPaymentPage = urlParams.get('success_payment') == 'true';

  if (successPaymentPage) {
    // After payment from the cart page
    let bookingIds = urlParams.get('booking_ids')?.split(',');

    // After quick checkout from the booking page
    if (bookingIds === undefined) {
      const bookingId = item_data.data('booking-id');

      if (bookingId) {
        bookingIds = [bookingId];
      }
    }

    if (bookingIds) {
      let items = [];
      let totalPrice = 0;
      let transactionId;
      
      // Find all bookings from the order
      for (const bookingId of bookingIds) {
        const booking = $(`[data-booking-id="${bookingId}"]`);
        totalPrice += parseFloat(booking.data('booking-total-price'));
        transactionId = booking.data('ga-transaction_id');

        items.push({
          'item_id': booking.data('ga-item_id'),
          'item_name': booking.data('ga-item_name'),
        });
      }

      gtag('event', 'purchase', {
        'value': totalPrice,
        'currency': 'USD',
        'transaction_id': transactionId,
        'items': items
      });
    }
  }
});
