$(document).ready(function () {
	$('.rst-menu-trigger').click(function () {
		$('.hd-right').toggleClass('active');
		$(this).toggleClass('exit');
		$(this).parents('#header').toggleClass('click-menu');
		$("body").toggleClass("open-menu");
		return false;
	});

	$('.admin-header .rst-menu-trigger').click(function () {
		$('.sidebar').toggleClass('active');
		$("body").toggleClass("open-menu");
		return false;
	});

	$('.switch input').change(function () {
		if ($(this).is(':checked')) {
			$(this).closest('.toggle-type').addClass('checked');
		} else {
			$(this).closest('.toggle-type').removeClass('checked');
		}
	});

	let index = 1;

	const on = (listener, query, fn) => {
		document.querySelectorAll(query).forEach(item => {
			item.addEventListener(listener, el => {
				fn(el);
			});
		});
	}

	// Click on .selectBtn
	on('click', '.selectBtn', item => {
		// Remove .toggle from all .selectDropdown elements
		document.querySelectorAll('.selectDropdown').forEach(dropdown => {
			if (dropdown !== item.target.nextElementSibling) {
				dropdown.classList.remove('toggle');
			}
		});

		const next = item.target.nextElementSibling;
		next.classList.toggle('toggle');
		next.style.zIndex = index++;
	});

	on('click', '.option', item => {
		document.querySelectorAll('.selectDropdown').forEach(dropdown => {
			dropdown.classList.remove('toggle');
		});

		item.target.parentElement.querySelectorAll('.option').forEach(option => {
			option.classList.remove('selected');
		});

		item.target.classList.add('selected');

		const parent = item.target.closest('.select').children[0];
		const newDataType = item.target.getAttribute('data-type');

		if (parent.getAttribute('data-type') !== newDataType) {
			parent.setAttribute('data-type', newDataType);
			parent.innerText = item.target.innerText;
		}

		// Find the .selectBtn element within the same .select container
		const selectBtn = item.target.closest('.select').querySelector('.selectBtn');
		// Add the .active class to the .selectBtn
		// selectBtn.classList.add('active');
	});

	$(document).on('click', function (event) {
		if (!$(event.target).closest('.selectDropdown, .box-filter').length) {
			$('.selectDropdown').removeClass('toggle');
		}
	});

	$(document).ready(function () {
		function toggleMenu() {
			if ($(window).width() <= 1024) {
				$(".menu-main > ul > li.menu-has-descendants > a")
					.off("click")
					.on("click", function (event) {
						event.preventDefault();
						var parentLi = $(this).parent("li");
						$(".menu-main > ul > li")
							.not(parentLi)
							.removeClass("open")
							.find("ul")
							.slideUp();
						parentLi.toggleClass("open");
						parentLi.find("ul").first().slideToggle();
					});
			} else {
				$(".menu-main > ul > li > a").off("click");
				$(".menu-main > ul > li > ul").css("display", "");
				$(".menu-main > ul > li").removeClass("open");
			}
		}

		toggleMenu();
		$(window).resize(toggleMenu);
	});

	jQuery(document).ready(function () {
		function checkWindowSize() {
			var windowWidth = jQuery(window).width();

			// Go by link on click on large screens
			jQuery('.box-top .has-menu-children > a').off('click');

			if (windowWidth <= 768) {
				// Toggle menu on click on small screens
				jQuery('.box-top .has-menu-children > a').on('click', function (event) {
					event.preventDefault(); // Prevent default action for a
					var parent = jQuery(this).closest('.has-menu-children');
					jQuery('.box-top .has-menu-children').not(parent).removeClass('active');
					parent.toggleClass('active');
				});
			}

			// Add hover event listeners to .has-menu-children on big screens
			if (windowWidth > 768) {
				jQuery('.box-top .has-menu-children').off('mouseenter mouseleave').hover(
					function () {
						jQuery(this).addClass('active');
					},
					function () {
						jQuery(this).removeClass('active');
					}
				);
			} else {
				jQuery('.box-top .has-menu-children').off('mouseenter mouseleave'); // Remove hover events for smaller screens
			}

			// Remove active class when clicking outside of .has-menu-children
			jQuery(document).off('click.outside').on('click.outside', function (event) {
				if (!jQuery(event.target).closest('.box-top .has-menu-children').length) {
					jQuery('.box-top .has-menu-children').removeClass('active');
				}
			});

		}

		checkWindowSize();

		jQuery(window).resize(function () {
			checkWindowSize();
		});
	});
});
