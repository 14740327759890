$(document).ready(() => {
  $('.ev-heart input').on('click', event => {
    const target = $(event.target)

    $.ajax({
      url: '/toggle_favorite/' + target.val(),
      method: 'post'
    })
  })
})
