$(document).ready(() => {
  window.showDesc = (slug) => {
    const x = $('#desc_' + slug)
    if (x.is(':visible') == false) {
      $('#desc_' + slug).show()
    } else {
      $('#desc_' + slug).hide()
    }
  }

  if ($('.tom-select').length > 0) {
    $('.tom-select').each((index, elem) => {
      const elemId = elem.id
      let tomSelectParams = {}

      if ($(elem).data('tom-select-search') != true) {
        tomSelectParams['controlInput'] = null
      }

      if (elemId == 'location_setter') {
        tomSelectParams['items'] = elem.getAttribute('data-location')
        tomSelectParams['render'] = {
          option: function (data, escape) {
            if (data.src === undefined) {
              return `<div>${data.text}</div>`
            } else {
              return `<div><img class="me-2" src="${data.src}">${data.text}</div>`
            }
          },
          item: function (item, escape) {
            if (item.src === undefined) {
              return `<div>${item.text}</div>`
            } else {
              return `<div><img class="me-2" src="${item.src}">${item.text}</div>`
            }
          }
        }
      } else if (elemId == 'quote_location' || elemId == 'gratuity_setter') {
        tomSelectParams['allowEmptyOption'] = true
      }
      
      new TomSelect(`#${elemId}`, tomSelectParams)
    })
  }

  $('#copyboard').on('click', function (e) {
    e.preventDefault()
    const copyText = $(this).attr('data-text')
    const textarea = document.createElement('textarea')
    textarea.textContent = copyText
    textarea.style.position = 'fixed'
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
    $('#share-link-copied').removeClass('d-none')
  })
})
