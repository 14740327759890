$(document).ready(() => {
  if ($('.menu-structure-editor').length > 0) {
    let savedMessageTimer

    $('.nested').each((i, nested) => {
      new Sortable(nested, {
        group: 'nested',
        animation: 150,
        fallbackOnBody: true,
        swapThreshold: 0.65,
        onEnd: function (event) {
          const item = event.item
          const itemId = item.dataset.id
          const parentNode = item.closest('.nested')
          const submitResult = $(item).find('.submit-result')

          const payload = {
            menu_item: {
              position: event.newIndex + 1,
              parent_id: parentNode.dataset.id || null
            }
          }

          $.ajax({
            url: '/system/menu_items/' + itemId,
            type: 'PATCH',
            data: payload,
            dataType: 'json',
            success: (data) => {
              submitResult.html('<i class="fa-solid fa-check"></i> Changes saved successfully')
              submitResult.addClass('text-success').removeClass('text-danger')

              clearTimeout(savedMessageTimer)

              savedMessageTimer = setTimeout(() => {
                submitResult.text('')
              }, 2000)
            },
            error: (data) => {
              submitResult.html(`<i class="fa-solid fa-circle-exclamation"></i> Error saving changes: ${data.responseJSON.base}`)
              submitResult.addClass('text-danger').removeClass('text-success')

              clearTimeout(savedMessageTimer)
            }
          })
        }
      })
    })
  }
})
