$(document).ready(() => {
  const createToastContent = (message, backgroundClass) => {
    return `<div class="toast align-items-center ${backgroundClass}" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body">${message}</div>
        <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
      </div>
    </div>`;
  };

  const checkStopList = (message) => {
    const stopList = ['', 'confirmed'];
    for (const stopItem of stopList) {
      if (message == stopItem) return true;
    }
  }

  const displayToast = (type, message) => {
    if (checkStopList(message)) return;

    const toastContainer = $('.toast-container');
    const backgroundClass = type == 'alert' || message.startsWith('Oops') ? 'bg-warning' : '';
    const toastContent = createToastContent(message, backgroundClass);

    toastContainer.append(toastContent);
    new bootstrap.Toast(toastContainer.children().last()).show();
  };

  window.displayToast = displayToast;

  const flashContent = $('.flash-content').data('content');
  for (const [type, message] of flashContent) {
    displayToast(type, message);
  }
});
