import Rails from '@rails/ujs'

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap

import 'trix'
import '@rails/actiontext'

import Sortable from 'sortablejs'
window.Sortable = Sortable

import 'video.js'

import Lightbox from 'bs5-lightbox'
window.Lightbox = Lightbox

import TomSelect from 'tom-select'
window.TomSelect = TomSelect

import.meta.glob('~/javascripts/**/*.js', { eager: true })
